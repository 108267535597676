<template>
  <v-text-field
    :value="value"
    v-bind="{ ...defaultProps, ...$attrs }"
    @input="handleInput"
  />
</template>

<script>
export default {
  name: 'TextFilter',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: '',
    }
  },
  data () {
    return {
      defaultProps: {
        backgroundColor: 'white',
        hideDetails: true,
        clearable: true,
        outlined: true,
        dense: true
      }
    };
  },
  methods: {
    handleInput (value) {
      this.$emit('input', value);
    },
  }
};
</script>
